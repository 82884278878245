import React from "react"
import { Link } from "gatsby"

export default function Buttoninternal({ link, children }) {
  return (
    <Link
      className="button my-4 bg-white text-black px-4 py-1 text-3xl lg:text-3xl rounded-md inline-block mx-5 text-center"
      to={link}
    >
      {children}
    </Link>
  )
}
