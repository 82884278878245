import React from "react"
import { Media } from "../Media"

import PageSection from "./page-section"
import SinglePressArticle from "./single-press-article"
import FlareSeparator from "./flare-separator"
import ButtonInternal from "./ButtonInternal"
import notes from "./../data/press"
import "../styles/press.scss"

const Press = () => {
  const recentNotesDesktop = notes.slice(0, 6)
  const recentNotesMobile = notes.slice(0, 4)

  return (
    <>
      <FlareSeparator />
      <PageSection title="Press" subtitle="" id="press">
        <div className="md:w-5/6 mx-auto">
          <Media greaterThanOrEqual="md" className="articles">
            {recentNotesDesktop.map((note, index) => (
              <SinglePressArticle
                note={note}
                key={`single-note-desktop-${index}`}
              />
            ))}
          </Media>
          <Media at="sm" className="mobileHeader">
            {recentNotesMobile.map((note, index) => (
              <SinglePressArticle
                note={note}
                key={`single-note-mobile-${index}`}
              />
            ))}
          </Media>

          <div className="my-12 lg:my-16 items-center flex flex-col">
            <ButtonInternal link="/press">SEE MORE</ButtonInternal>
          </div>
        </div>
      </PageSection>
    </>
  )
}

export default Press
