import React from "react"

import ButtonInternal from "./ButtonInternal"
import "../styles/transactions.scss"

const Transactions = () => {
  return (
    <div id="transactions">
      <div className="text mx-auto mt-20 md:mt-20 xl:full flex items-center justify-center ">
        <ButtonInternal link="/transactions">TRANSACTIONS</ButtonInternal>
      </div>
    </div>
  )
}

export default Transactions
