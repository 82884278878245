import React from "react"
import { Media } from "../Media"

import PageSection from "./page-section"
import "../styles/standings.scss"
import standings from "./../data/standings"

const Standings = () => {
  const list = standings.list
  const top = standings.top

  return (
    <PageSection
      title="Standings"
      subtitle="Updated weekly. See who's on top (for now!)"
      id="standings"
    >
      <div className="flex flex-row justify-center items-center">
        <Media greaterThanOrEqual="md" className="w-1/3">
          <img
            src={top[1].image}
            alt="Standing 2º"
            className="w-3/4 mx-auto"
            width="1236"
            height="1513"
          />
        </Media>
        <img
          src={top[0].image}
          className="w-5/6 md:w-1/3"
          alt="Standing 1º"
          width="1236"
          height="1513"
        />
        <Media greaterThanOrEqual="md" className="w-1/3">
          <img
            src={top[2].image}
            alt="Standing 3º"
            className="w-3/4 mx-auto"
            width="1236"
            height="1513"
          />
        </Media>
      </div>

      <div className="w-5/6 mx-auto flex flex-col md:flex-row">
        <div className="column flex-1 flex flex-col items-center justify-center">
          {list.slice(0, 7).map((el, index) => (
            <img
              key={`standings-first-colum-${index}`}
              src={el.image}
              alt={`Position ${index + 1} of ${list.length + 1}`}
            />
          ))}
        </div>
        <div className="column flex-1 flex flex-col items-center justify-center">
          {list.slice(7, 14).map((el, index) => (
            <img
              key={`standings-second-colum-${index}`}
              src={el.image}
              alt={`Position ${index + 8} of ${list.length + 1}`}
            />
          ))}
        </div>
      </div>
    </PageSection>
  )
}

export default Standings
