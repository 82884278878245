import React from "react"
import { Link } from "gatsby"
import { Media } from "./../Media"

import PageSection from "./page-section"

import players from "./../data/players"
import "../styles/players.scss"

const SinglePlayerCard = ({ player }) => {
  const cardSizes =
    "w-1/2 md:w-1/5 lg:w-1/7 p-2 md:p-2 lg:p-2 xl:p-4 mb-2 lg:mb-4"

  const stopMovie = e => {
    e.target.pause()
    e.target.currentTime = 0
  }

  const playMovie = e => {
    e.target.play()
  }

  return (
    <div
      key={`player-card-${player.text}`}
      data-sal-duration="500"
      data-sal="zoom-in"
      className={cardSizes}
    >
      <Link to={player.url}>
        <div className="relative">
          <Media greaterThanOrEqual="md">
            {player.video && (
              <video
                muted={true}
                loop={true}
                className="block absolute inset-0 opacity-0 hover:opacity-100 transition-all duration-300"
                onMouseOver={playMovie}
                onMouseOut={stopMovie}
                onFocus={playMovie}
                onBlur={stopMovie}
              >
                <source src={player.video} type="video/mp4" />
              </video>
            )}
          </Media>
          <img
            src={player.image}
            alt={`Team ${player.text}`}
            width="1000"
            height="1482"
          />
        </div>
      </Link>

      <p className="fm-russo text-center text-xl lg:text-xl xl:text-2xl px-1 leading-6 pt-4">
        Team <br /> {player.text}
      </p>
    </div>
  )
}

const Players = () => {
  const filteredPlaters = players.filter(el => el.image)

  return (
    <PageSection
      title="Players, assemble!"
      subtitle="Click a Card to See Each Team"
      id="players"
    >
      <div className="flex flex-wrap justify-center md:w-5/6 mx-auto">
        {filteredPlaters.map((player, index) => (
          <SinglePlayerCard
            key={`player-card-${player.text}`}
            player={player}
          />
        ))}
      </div>
    </PageSection>
  )
}

export default Players
