import thor from "../images/cards/new_thor.jpg"
import deadpool from "../images/cards/new_deadpool.jpg"
import nebula from "../images/cards/new_nebula.jpg"
import captain from "../images/cards/new_captain.jpg"
import scarlet from "../images/cards/new_scarlet.jpg"
import mberry from "../images/cards/new_mberry.jpg"
import ant from "../images/cards/new_ant.jpg"
import iron from "../images/cards/new_iron.jpg"
import falcon from "../images/cards/new_falcon.jpg"
import starlord from "../images/cards/new_starlord.jpg"
import jrusso from "../images/cards/new_jrusso.jpg"
import spiderman from "../images/cards/new_spiderman.jpg"
import liu from "../images/cards/new_liu.jpg"
import pom from "../images/cards/new_pom.jpg"

import BerryRoster from "./../images/cards/ASFFL_ROSTERS_Berry.jpg"
import GillanRoster from "./../images/cards/ASFFL_ROSTERS_Gillan.jpg"
import HemsworthRoster from "./../images/cards/ASFFL_ROSTERS_Hemsworth.jpg"
import HollandRoster from "./../images/cards/ASFFL_ROSTERS_Holland.jpg"
import MackieRoster from "./../images/cards/ASFFL_ROSTERS_Mackie.jpg"
import OlsenRoster from "./../images/cards/ASFFL_ROSTERS_Olsen.jpg"
import PomRoster from "./../images/cards/ASFFL_ROSTERS_Pom.jpg"
import PrattRoster from "./../images/cards/ASFFL_ROSTERS_Pratt.jpg"
import RDJRoster from "./../images/cards/ASFFL_ROSTERS_RDJ.jpg"
import ReynoldsRoster from "./../images/cards/ASFFL_ROSTERS_Reynolds.jpg"
import RuddRoster from "./../images/cards/ASFFL_ROSTERS_Rudd.jpg"
import RussoRoster from "./../images/cards/ASFFL_ROSTERS_Russo.jpg"
import EvansRoster from "./../images/cards/ASFFL_ROSTERS_evans.jpg"
import LiuRoster from "./../images/cards/ASFFL_ROSTERS_liu.jpg"

import AntMan_v01 from "./../images/cards/videos/tan_asfl_card_antman_v01_small.mp4"
import AnthonyMackie_v01 from "./../images/cards/videos/tan_asfl_card_anthonymackie_v01_small.mp4"
import ChrisEvans_v01 from "./../images/cards/videos/tan_asfl_card_chrisevans_v01_small.mp4"
import Deadpool_v01 from "./../images/cards/videos/tan_asfl_card_deadpool_v01_small.mp4"
import IronMan_v02 from "./../images/cards/videos/tan_asfl_card_ironman_v02_small.mp4"
import JoeR_v01 from "./../images/cards/videos/tan_asfl_card_joer_v01_small.mp4"
import Matthew_v01 from "./../images/cards/videos/tan_asfl_card_matthew_v01_small.mp4"
import Nebula_v01 from "./../images/cards/videos/tan_asfl_card_nebula_v01_small.mp4"
import Pom_v01 from "./../images/cards/videos/tan_asfl_card_pom_v01_small.mp4"
import Scarlt_v01 from "./../images/cards/videos/tan_asfl_card_scarlt_v01_small.mp4"
import Shang_v01 from "./../images/cards/videos/tan_asfl_card_shang_v01_small.mp4"
import Starlord_v01 from "./../images/cards/videos/tan_asfl_card_starlord_v01_small.mp4"
import Thor_v01 from "./../images/cards/videos/tan_asfl_card_thor_v01_small.mp4"
import TomHoll_v01 from "./../images/cards/videos/tan_asfl_card_tomholl_v01_small.mp4"

import downey from "./../images/charity/asffl-charity-Downey.png"
import berry from "./../images/charity/asffl-charity-berry.png"
import evans from "./../images/charity/asffl-charity-evans.png"
import gillan from "./../images/charity/asffl-charity-gillan.png"
import hemsworth from "./../images/charity/asffl-charity-hemsworth.png"
import holland from "./../images/charity/asffl-charity-holland.png"
import klementieff from "./../images/charity/asffl-charity-klementieff.png"
import mackie from "./../images/charity/asffl-charity-mackie.png"
import olsen from "./../images/charity/asffl-charity-olsen.png"
import pratt from "./../images/charity/asffl-charity-pratt.png"
import reynolds from "./../images/charity/asffl-charity-reynolds.png"
import rudd from "./../images/charity/asffl-charity-rudd.png"
import russo from "./../images/charity/asffl-charity-russo.png"
import liuCharity from "./../images/charity/asffl-charity-liu.png"

import CircleEvans from "./../images/cards/circles/player-circle-evans.png"
import CircleBerry from "./../images/cards/circles/player-circle-berry.png"
import CircleGillan from "./../images/cards/circles/player-circle-gillan.png"
import CircleHemsworh from "./../images/cards/circles/player-circle-hemsworh.png"
import CircleHolland from "./../images/cards/circles/player-circle-holland.png"
import CircleKlementieff from "./../images/cards/circles/player-circle-klementieff.png"
import CircleLiu from "./../images/cards/circles/player-circle-liu.png"
import CircleMackie from "./../images/cards/circles/player-circle-mackie.png"
import CircleOlsen from "./../images/cards/circles/player-circle-olsen.png"
import CirclePratt from "./../images/cards/circles/player-circle-pratt.png"
import CircleRdj from "./../images/cards/circles/player-circle-rdj.png"
import CircleReynolds from "./../images/cards/circles/player-circle-reynolds.png"
import CircleRudd from "./../images/cards/circles/player-circle-rudd.png"
import CircleRusso from "./../images/cards/circles/player-circle-russo.png"

const data = [
  {
    id: "hemsworth",
    charityOrder: 7,
    text: "Hemsworth",
    image: thor,
    url: "/teams/hemsworth",
    rooster: HemsworthRoster,
    video: Thor_v01,
    spreadsheet: "TEAM HEMSWORTH",
    charityImage: hemsworth,
    charityUrl: "https://www.childhood.org.au/",
    seasonOne: ["1ST PLACE", "10-3-0"],
    abilities: ["SUPER STRENGTH", "GOD OF THUNDER"],
    weakness: ["ONLINE GAMING TROLLS", "BROTHER"],
    circle: CircleHemsworh,
  },
  {
    id: "reynolds",
    charityOrder: 3,
    text: "Reynolds",
    image: deadpool,
    url: "/teams/reynolds",
    rooster: ReynoldsRoster,
    video: Deadpool_v01,
    spreadsheet: "TEAM REYNOLDS",
    charityImage: reynolds,
    charityUrl: "https://www.sickkidsfoundation.com/",
    seasonOne: ["3RD PLACE", "9-3-1"],
    abilities: ["REGENERATION", "EXTREMELY ANNOYING"],
    weakness: ["COWS", "KITTENS"],
    circle: CircleReynolds,
  },
  {
    id: "gillan",
    charityOrder: 10,
    text: "Gillan",
    image: nebula,
    url: "/teams/gillan",
    rooster: GillanRoster,
    video: Nebula_v01,
    spreadsheet: "TEAM GILLAN",
    charityImage: gillan,
    charityUrl: "https://www.mikeysline.co.uk/",
    seasonOne: ["5TH PLACE", "7-6-0"],
    abilities: ["BATTLE STRATEGIST", "INTELLECT"],
    weakness: ["NEED FOR THANOS'S APPROVAL"],
    circle: CircleGillan,
  },
  {
    id: "evans",
    charityOrder: 6,
    text: "Evans",
    image: captain,
    url: "/teams/evans",
    rooster: EvansRoster,
    video: ChrisEvans_v01,
    spreadsheet: "TEAM EVANS",
    charityImage: evans,
    charityUrl: "https://www.christophershaven.org/",
    seasonOne: ["11TH PLACE", "5-8-0"],
    abilities: ["TEAM LEADERSHIP", "SUPER STRENGTH"],
    weakness: ["BAD LANGUAGE", "DOGS"],
    circle: CircleEvans,
  },
  {
    id: "klementieff",
    charityOrder: 11,
    text: "Klementieff",
    image: pom,
    url: "/teams/klementieff",
    rooster: PomRoster,
    video: Pom_v01,
    spreadsheet: "TEAM KLEMENTIEFF",
    charityImage: klementieff,
    charityUrl: "https://timesupnow.org/",
    seasonOne: ["NEW TO THE LEAGUE"],
    abilities: ["EMPATH", "TELEPATH"],
    weakness: ["UNKNOWN"],
    circle: CircleKlementieff,
  },
  {
    id: "olsen",
    charityOrder: 13,
    text: "Olsen",
    image: scarlet,
    url: "/teams/olsen",
    rooster: OlsenRoster,
    video: Scarlt_v01,
    spreadsheet: "TEAM OLSEN",
    charityImage: olsen,
    charityUrl: "https://therapefoundation.org/",
    seasonOne: ["6TH PLACE", "6-7-0"],
    abilities: ["CHAOS MAGIC", "TELEPATHY"],
    weakness: ["NERFED IN THE MCU"],
    circle: CircleOlsen,
  },
  {
    id: "berry",
    charityOrder: 4,
    text: "Berry",
    image: mberry,
    url: "/teams/berry",
    rooster: BerryRoster,
    video: Matthew_v01,
    spreadsheet: "TEAM BERRY",
    charityImage: berry,
    charityUrl: "https://www.v.org/",
    seasonOne: ["4TH PLACE", "8-5-0"],
    abilities: ["WINNING AT FAKE FOOTBALL", "GRABBING BRIEFCASES"],
    weakness: ["ONCE WORKED FOR HYDRA HAIR"],
    circle: CircleBerry,
  },
  {
    id: "rudd",
    charityOrder: 12,
    text: "Rudd",
    image: ant,
    url: "/teams/rudd",
    rooster: RuddRoster,
    video: AntMan_v01,
    spreadsheet: "TEAM RUDD",
    charityImage: rudd,
    charityUrl: "https://www.childrensmercy.org/",
    seasonOne: ["11TH PLACE", "5-8-0"],
    abilities: ["GENIUS LEVEL INTELLECT", "SUBATOMIC/GIANT TRANSFORMATION"],
    weakness: ["STAR WARS MOVIE REFERENCES USED IN BATTLE"],
    circle: CircleRudd,
  },
  {
    id: "downey",
    charityOrder: 9,
    text: "Downey Jr.",
    image: iron,
    url: "/teams/downey",
    rooster: RDJRoster,
    video: IronMan_v02,
    spreadsheet: "TEAM DOWNEY JR",
    charityImage: downey,
    charityUrl: "https://www.footprintcoalition.com/",
    seasonOne: ["13TH PLACE", "5-8-0"],
    abilities: ["SUPERINTELLIGENCE", "UNLIMITED IRON SUITS"],
    weakness: ["CHEESEBURGERS"],
    circle: CircleRdj,
  },
  {
    id: "mackie",
    charityOrder: 14,
    text: "Mackie",
    image: falcon,
    url: "/teams/mackie",
    rooster: MackieRoster,
    video: AnthonyMackie_v01,
    spreadsheet: "TEAM MACKIE",
    charityImage: mackie,
    charityUrl: "https://www.stemnola.com/",
    seasonOne: ["9TH PLACE", "5-8-0"],
    abilities: ["TEAM LEADERSHIP", "TACTICIAN & STRATEGIST"],
    weakness: ["BIRD CAGES", "RACING STEVE ROGERS"],
    circle: CircleMackie,
  },
  {
    id: "pratt",
    charityOrder: 1,
    text: "Pratt",
    image: starlord,
    url: "/teams/pratt",
    rooster: PrattRoster,
    video: Starlord_v01,
    spreadsheet: "TEAM PRATT",
    charityImage: pratt,
    charityUrl: "http://specialolympicswashington.org/",
    seasonOne: ["8TH PLACE", "6-7-0"],
    abilities: ["SUPER STRENGTH & DURABILITY", "MASTER TACTICIAN"],
    weakness: ["BAD FOOTLOOSE", "REVIEWS"],
    circle: CirclePratt,
  },
  {
    id: "russo",
    charityOrder: 8,
    text: "Russo",
    image: jrusso,
    url: "/teams/russo",
    rooster: RussoRoster,
    video: JoeR_v01,
    spreadsheet: "TEAM RUSSO",
    charityImage: russo,
    charityUrl: "https://www.arthritis.org/",
    seasonOne: ["10TH PLACE", "4-9-0"],
    abilities: ["TEAM LEADERSHIP", "MULTITASK MASTER"],
    weakness: ["SPOILERS"],
    circle: CircleRusso,
  },
  {
    id: "holland",
    charityOrder: 5,
    text: "Holland",
    image: spiderman,
    url: "/teams/holland",
    rooster: HollandRoster,
    video: TomHoll_v01,
    spreadsheet: "TEAM HOLLAND",
    charityImage: holland,
    charityUrl: "https://www.thebrotherstrust.org/",
    seasonOne: ["14TH PLACE", "4-9-0"],
    abilities: ["SUPER REFLEXES", "SUPER STRENGTH"],
    weakness: ["GREAT RESPONSIBILITIES", "SPIDERS"],
    circle: CircleHolland,
  },
  {
    id: "liu",
    charityOrder: 2,
    text: "Liu",
    image: liu,
    url: "/teams/lui",
    rooster: LiuRoster,
    video: Shang_v01,
    spreadsheet: "TEAM LIU",
    charityImage: liuCharity,
    charityUrl: "https://www.unicef.ca/en",
    seasonOne: ["NEW TO THE LEAGUE"],
    abilities: ["KUNG FU ARMY", "MASTER OF WEAPONS"],
    weakness: ["ICE CREAM"],
    circle: CircleLiu,
  },
]

export default data
