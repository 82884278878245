import React from "react"

import { Media } from "../Media"

export default function ResponsiveImage({
  image = false,
  imageDesktop = false,
  classes = "",
  alt = "Image",
  widthDesktop = "",
  heigthDesktop = "",
  widthMobile = "",
  heigthMobile = "",
}) {
  return (
    <>
      {image && (
        <Media at="sm">
          <img
            src={image}
            alt={alt}
            className={classes}
            width={widthMobile}
            heigth={heigthMobile}
          />
        </Media>
      )}

      {imageDesktop && (
        <Media greaterThanOrEqual="md">
          <img
            src={imageDesktop}
            alt={alt}
            className={classes}
            width={widthDesktop}
            heigth={heigthDesktop}
          />
        </Media>
      )}
    </>
  )
}
