import React from "react"

import "../styles/overview.scss"

const Ready = () => {
  return (
    <div id="overview" className="pt-8">
      <div className="text w-5/6 md:w-3/4 mx-auto mt-24 md:mt-0 xl:w-4/5 xxl:w-2/3">
        <h2 className="w-11/12 mt-8 mb-8 md:mb-6 lg:text-5xl xl:mb-12 xl:mt-0 xxl:mt-16">
          Are you ready for some superhero football?
        </h2>
        <p className="description">
          All the drama of football, all the fantasy fun of your favorite
          superheroes, all the star-studded trash talk you could ask for — and
          all for a great cause!
        </p>
        <p className="my-6 description">
          <a
            href="http://agbo.com/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            AGBO
          </a>{" "}
          has recruited some of the biggest superheroes in the world (i.e., the
          biggest superstars in the real world) to settle their scores. All
          season long, they’ll compete in fantasy football for their charities
          of choice, with donations thanks to{" "}
          <a
            href="https://www.fanduel.com/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            FanDuel
          </a>
          .
        </p>
      </div>
    </div>
  )
}

export default Ready
