import React from "react"

export default function PageSection({ title, subtitle = "", id, children }) {
  return (
    <>
      <section id={id} className="relative z-10">
        <div className="pt-12 md:pt-32 xl:full">
          {title && (
            <h2
              className={`w-11/12 ${
                subtitle ? "mb-2 md:mb-2" : "mb-16 md:mb-16"
              } text-4xl lg:text-5xl`}
            >
              {title}
            </h2>
          )}
          {subtitle && (
            <p className="mb-8 md:mb-16 text-2xl fm-oswald italic text-center ml-a mr-a block ">
              {subtitle}
            </p>
          )}
          <>{children}</>
        </div>
      </section>
    </>
  )
}
