import React, { useState, useEffect, useCallback } from "react"

import PageSection from "./page-section"
import Swipercontainer from "./swiperContainer"
import ResponsiveImage from "./responsive-image"

import "../styles/schedule.scss"
import scheduleCalendar from "./../data/schedule"

const WeekRow = ({ index, moveslider, active, row }) => {
  return (
    <>
      <button
        className="px-2 pb-4 text-lg whitespace-no-wrap cursor-pointer clickable focus:outline-none"
        onClick={() => moveslider(index)}
        onKeyDown={() => moveslider(index)}
      >
        <span className={`pb-2 hover:underline ${active ? "underline" : ""}`}>
          Week {index + 1}
        </span>
      </button>
      {index !== row * 7 - 1 && <span className="pb-4 separator">|</span>}
      {index === row * 7 - 1 && (
        <span className="inline pb-4 separator md:hidden">|</span>
      )}
    </>
  )
}

const Schedule = () => {
  const [currentSlide, setCurrentslide] = useState(0)

  const moveslider = id => {
    setCurrentslide(id)
  }

  const sliderChange = useCallback(
    id => {
      setCurrentslide(id)
    },
    [setCurrentslide]
  )

  const filteredScheduleCalendar = scheduleCalendar.filter(el => el.enabled)

  useEffect(() => {
    // Update this value every week
    sliderChange(16)
  }, [sliderChange])

  return (
    <>
      <PageSection title="Schedule" id="schedule">
        <Swipercontainer
          name="schedule"
          changeSlide={currentSlide}
          sliderChange={sliderChange}
        >
          {filteredScheduleCalendar.map(({ week, image, imageDesktop }) => (
            <div key={`schedule-${week}-slide`}>
              <ResponsiveImage
                image={image}
                imageDesktop={imageDesktop}
                classes="m-auto w-10/12"
                alt={`Week ${week}`}
              />
            </div>
          ))}
        </Swipercontainer>

        <div
          className="flex flex-wrap items-center justify-center px-16 py-16 mx-auto"
          style={{ maxWidth: "800px" }}
        >
          {filteredScheduleCalendar.slice(0, 7).map((_el, index) => (
            <WeekRow
              key={`schedule-${index}-weeks`}
              index={index}
              moveslider={moveslider}
              active={currentSlide === index}
              row={1}
            />
          ))}

          {filteredScheduleCalendar.slice(7, 14).map((_el, index) => (
            <WeekRow
              key={`schedule-${index}-weeks`}
              index={index + 7}
              moveslider={moveslider}
              active={currentSlide === index + 7}
              row={2}
            />
          ))}

          {filteredScheduleCalendar.slice(14, 17).map((_el, index) => (
            <WeekRow
              key={`schedule-${index}-weeks`}
              index={index + 14}
              moveslider={moveslider}
              active={currentSlide === index + 14}
              row={3}
            />
          ))}

          {false &&
            filteredScheduleCalendar.slice(0, 7).map((_el, index) => (
              <span key={`schedule-${index}-weeks`}>
                <button
                  className="px-2 pb-4 text-xl whitespace-no-wrap cursor-pointer clickable focus:outline-none"
                  onClick={() => moveslider(index)}
                  onKeyDown={() => moveslider(index)}
                >
                  <span
                    className={`pb-2 hover:underline ${
                      index === currentSlide ? "underline" : ""
                    }`}
                  >
                    Week {index + 1}
                  </span>
                </button>
                {index !== filteredScheduleCalendar.length - 1 &&
                  index !== 6 && <span className="pb-4 separator">|</span>}
                {index === 6 && (
                  <span className="inline pb-4 separator md:hidden">|</span>
                )}
              </span>
            ))}
        </div>
      </PageSection>
    </>
  )
}

export default Schedule
