import WK1 from "../images/weeks/results/ASFFL_Weekly_Results_WK1.jpg"
import WK1M from "../images/weeks/results/ASFFL_Results_M_wk1.jpg"

import WK2 from "../images/weeks/results/ASFFL_Weekly_Results_WK2.jpg"
import WK2M from "../images/weeks/results/ASFFL_Results_M_wk2.jpg"

import WK3 from "../images/weeks/results/ASFFL_Weekly_Results_WK3.jpg"
import WK3M from "../images/weeks/results/ASFFL_Results_M_wk3.jpg"

import WK4 from "../images/weeks/results/ASFFL_Weekly_Results_WK4.jpg"
import WK4M from "../images/weeks/results/ASFFL_Results_M_wk4.jpg"

import WK5 from "../images/weeks/results/ASFFL_Weekly_Schedule_WK5.jpg"
import WK5M from "../images/weeks/results/ASFFL_RESULTS_M_wk5.jpg"

import WK6 from "..//images/weeks/results/ASFFL_Weekly_Schedule_WK6.jpg"
import WK6M from "..//images/weeks/results/ASFFL_RESULTS_M_wk6.jpg"

import WK7 from "../images/weeks/results/ASFFL_Weekly_Schedule_WK7.jpg"
import WK7M from "../images/weeks/results/ASFFL_RESULTS_M_wk7.jpg"

import WK8 from "../images/weeks/results/ASFFL_Weekly_Schedule_WK8.jpg"
import WK8M from "../images/weeks/results/ASFFL_RESULTS_M_wk8.jpg"

import WK9 from "../images/weeks/results/ASFFL_Weekly_Schedule_WK9.jpg"
import WK9M from "../images/weeks/results/ASFFL_RESULTS_M_wk9.jpg"

import WK10 from "../images/weeks/results/ASFFL_Weekly_Schedule_WK10.jpg"
import WK10M from "../images/weeks/results/ASFFL_RESULTS_M_wk10.jpg"

import WK11 from "../images/weeks/results/ASFFL_Weekly_Schedule_WK11.jpg"
import WK11M from "../images/weeks/results/ASFFL_RESULTS_M_wk11.jpg"

import WK12 from "../images/weeks/results/ASFFL_Weekly_Schedule_WK12.jpg"
import WK12M from "../images/weeks/results/ASFFL_RESULTS_M_wk12.jpg"

import WK13 from "../images/weeks/results/ASFFL_Weekly_Schedule_WK13.jpg"
import WK13M from "../images/weeks/results/ASFFL_RESULTS_M_wk13.jpg"

import PlayoffWK1 from "../images/weeks/playoffs/ASFFL_Web_Playoff_Results_wk1.jpg"
import PlayoffWK1M from "../images/weeks/playoffs/ASFFL_Web_Playoff_Results_wk1a.jpg"

import PlayoffWK2 from "../images/weeks/playoffs/ASFFL_Web_PlayoffResults_wk2.jpg"
import PlayoffWK2M from "../images/weeks/playoffs/ASFFL_Social_PlayoffResults_wk2.jpg"

import PlayoffWK3 from "../images/weeks/playoffs/ASFFL_Web_PlayoffBrackets_wk3_Results.jpg"
import PlayoffWK3M from "../images/weeks/playoffs/ASFFL_Web_PlayoffBrackets_wk3_Results.jpg"

const data = [
  { week: 1, image: WK1M, imageDesktop: WK1, enabled: true },
  { week: 2, image: WK2M, imageDesktop: WK2, enabled: true },
  { week: 3, image: WK3M, imageDesktop: WK3, enabled: true },
  { week: 4, image: WK4M, imageDesktop: WK4, enabled: true },
  { week: 5, image: WK5M, imageDesktop: WK5, enabled: true },
  { week: 6, image: WK6M, imageDesktop: WK6, enabled: true },
  { week: 7, image: WK7M, imageDesktop: WK7, enabled: true },
  { week: 8, image: WK8M, imageDesktop: WK8, enabled: true },
  { week: 9, image: WK9M, imageDesktop: WK9, enabled: true },
  { week: 10, image: WK10M, imageDesktop: WK10, enabled: true },
  { week: 11, image: WK11M, imageDesktop: WK11, enabled: true },
  { week: 12, image: WK12M, imageDesktop: WK12, enabled: true },
  { week: 13, image: WK13M, imageDesktop: WK13, enabled: true },
  { week: 14, image: PlayoffWK1M, imageDesktop: PlayoffWK1, enabled: true },
  { week: 15, image: PlayoffWK2M, imageDesktop: PlayoffWK2, enabled: true },
  { week: 16, image: PlayoffWK3M, imageDesktop: PlayoffWK3, enabled: true },
]

export default data
