import React from "react"
import PageSection from "./page-section"

import social1 from "./../images/social/social1.jpg"
import social2 from "./../images/social/social2.jpg"
import social3 from "./../images/social/social3.jpg"
import social4 from "./../images/social/social4.jpg"
import social5 from "./../images/social/social5.jpg"

const socialWrapperClass = "flex flex-col flex-1 w-full h-64"

const SocialFeedYoutube = () => (
  <div className={socialWrapperClass} style={{ minHeight: "460px" }}>
    <iframe
      title="youtube-playlist"
      width="100%"
      height="410"
      src="https://www.youtube.com/embed/videoseries?list=PLtwujFMxTRWiB_Y0Dm4eZk-70tlihvpc9"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  </div>
)

const SocialFeedTwitter = () => (
  <div className={socialWrapperClass} style={{ minHeight: "460px" }}>
    <div className="elfsight-app-ae0c93ed-25ce-4dad-a880-b9665ef14d0e"></div>
  </div>
)

const SocialFeedInstagram = () => (
  <div className={socialWrapperClass} style={{ minHeight: "460px" }}>
    <div className="flex items-center justify-center">
      <div className="elfsight-app-82e6523a-8269-4470-b849-42606ee9736a"></div>
    </div>
  </div>
)

const SocialGridElement = ({ post: { type, image, alt, url } }) => (
  <div className="flex justify-center w-full p-2 my-auto lg:w-1/3 md:p-2 flex-colmx-auto">
    <a
      href={url}
      target="_blank"
      title={alt}
      rel="noreferrer"
      className="block"
    >
      <img src={image} alt={alt} />
    </a>
  </div>
)

const Social = () => {
  const socialElements = [
    {
      type: "ig",
      image: social5,
      url: "https://www.instagram.com/p/CFAGORGgiPU/",
      alt:
        "Albert Einstein once said ‘You have to learn the rules of the game. And then you have to play them better than anyone else.’ In my experience with the @agboleague fantasy football charity league you don’t have to learn the rules at all, and you still win. Thanks to #FanDuel there is a $1M prize pool up for grabs, and I am playing for @auschildhood. See you on the field. #AGBOSuperheroleague",
    },
    {
      type: "tw",
      image: social4,
      url: "https://www.instagram.com/p/CEuo1zFFL-k/",
      alt:
        "And we’re back,,, let the trash talking begin. The #AGBOSuperheroLeague has assembled some of my closest friends and fellow superhero opponents for a great cause... This year, thanks to #FanDuel the stakes are higher than ever before ! There’s $1M in prizes up for grabs and @officialfootprintcoalition is my charity of choice... But most importantly this season is dedicated to @chadwickboseman and an additional $250K will be donated throughout the season to charities in his memory. Watch all the action play out on www.AGBOSuperheroLeague.com. Peep out the @agboleague page on IG (🎥 @jimmy_rich ) #TeamStark #Chadwickforever",
    },
    {
      type: "tw",
      image: social3,
      url: "https://twitter.com/RobertDowneyJr/status/1302002031268450306",
      alt:
        "And we’re back...let the trash talking begin. The #AGBOSuperheroLeague has assembled some of my closest friends and fellow superhero opponents for a great cause...",
    },
    {
      type: "tw",
      image: social2,
      url: "https://twitter.com/RobertDowneyJr/status/1302002449620893700",
      alt:
        "This year, thanks to #FanDuel the stakes are higher than ever before ! There’s $1M in prizes and @fp_coalition is my charity of choice...But most importantly this season is dedicated to #ChadwickBoseman and an additional $250K will be donated throughout to charities in his memory",
    },
    {
      type: "tw",
      image: social1,
      url: "https://twitter.com/RobertDowneyJr/status/1302002598845845507",
      alt:
        "Watch all the action play out on http://AGBOSuperheroLeague.com. Peep out the @agboleague page #TeamStark #ChadwickForever",
    },
  ]

  return (
    <PageSection title="Social" subtitle="#AGBOSuperheroLeague" id="social">
      <div className="flex flex-col flex-wrap justify-center mx-auto lg:flex-row md:w-5/6">
        <div className="flex flex-col justify-end w-full px-4 lg:w-1/3 md:p-4">
          <SocialFeedYoutube />
          <div className="py-8 text-center">
            <a
              className="p-4 text-lg text-center"
              href="https://www.youtube.com/playlist?list=PLtwujFMxTRWiB_Y0Dm4eZk-70tlihvpc9"
              target="_blank"
              rel="noreferrer"
            >
              VIEW YOUTUBE
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center justify-end w-full px-4 lg:w-1/3 md:p-4">
          <SocialFeedTwitter />
          <div className="py-8 text-center">
            <a
              className="p-4 text-lg text-center"
              href="https://twitter.com/agboleague/"
              target="_blank"
              rel="noreferrer"
            >
              VIEW TWITTER
            </a>
          </div>
        </div>

        <div className="flex flex-col justify-end w-full px-4 lg:w-1/3 md:p-4">
          <SocialFeedInstagram />
          <div className="py-8 text-center">
            <a
              className="p-4 text-lg text-center"
              href="https://www.instagram.com/agboleague/"
              target="_blank"
              rel="noreferrer"
            >
              VIEW INSTAGRAM
            </a>
          </div>
        </div>
      </div>

      {/* Grid */}
      <div className="flex flex-col flex-wrap justify-center mx-auto mt-16 lg:flex-row md:w-5/6">
        {socialElements.map((el, index) => (
          <SocialGridElement post={el} key={`social-grid-${index}`} />
        ))}
      </div>
    </PageSection>
  )
}

export default Social
