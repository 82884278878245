import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Overview from "../components/overview"
import Players from "../components/players"
import Standings from "../components/standings"
import Transactions from "../components/transactions"
import Schedule from "../components/schedule"

import Social from "../components/social"
import Press from "../components/press"
import Charities from "../components/charities"
import NewsLetter from "../components/newsletter"

export default function Index({ location }) {
  return (
    <Layout>
      <SEO title="Home" />
      <Overview />
      <Players />
      <Standings />
      <Transactions />
      <Schedule />

      <Social />
      <Press />
      <Charities />
      <NewsLetter />
    </Layout>
  )
}
