import React from "react"

import PageSection from "./page-section"
import ButtonInternal from "./ButtonInternal"

import FlareSeparator from "./flare-separator"
import players from "./../data/players"

const Charities = () => {
  return (
    <>
      <FlareSeparator />
      <PageSection
        title="Charities"
        subtitle="Play with Purpose"
        id="charities"
      >
        <div className="flex flex-wrap justify-center w-5/6 mx-auto mt-2">
          {players
            .sort((a, b) => a.charityOrder - b.charityOrder)
            .map((player, index) => (
              <>
                {player.charityUrl && (
                  <div key={player.spreadsheet} className="w-1/2 lg:w-1/5">
                    <a
                      href={player.charityUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        data-sal-duration="300"
                        data-sal="zoom-in"
                        className="p-2"
                        src={player.charityImage}
                        alt={player.spreadsheet}
                      />
                    </a>
                  </div>
                )}
              </>
            ))}
        </div>

        <div className="flex flex-col items-center my-12 lg:my-16">
          <div className="flex flex-col py-8 md:flex-row">
            <ButtonInternal link="/season-1-results">
              SEASON 1 RESULTS
            </ButtonInternal>

            <ButtonInternal link="/league-rules-settings-schedule">
              RULES & SETTINGS
            </ButtonInternal>
          </div>
        </div>
      </PageSection>
    </>
  )
}

export default Charities
