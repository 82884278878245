import top1 from "./../images/standings/final/ASFL_1st_Place.png"
import top2 from "./../images/standings/final/ASFL_2nd_Place.png"
import top3 from "./../images/standings/final/ASFL_3rd_Place.png"

import pos1 from "./../images/standings/final/ASfFL_TeamRank_Final_01.png"
import pos2 from "./../images/standings/final/ASfFL_TeamRank_Final_02.png"
import pos3 from "./../images/standings/final/ASfFL_TeamRank_Final_03.png"
import pos4 from "./../images/standings/final/ASfFL_TeamRank_Final_04.png"
import pos5 from "./../images/standings/final/ASfFL_TeamRank_Final_05.png"
import pos6 from "./../images/standings/final/ASfFL_TeamRank_Final_06.png"
import pos7 from "./../images/standings/final/ASfFL_TeamRank_Final_07.png"
import pos8 from "./../images/standings/final/ASfFL_TeamRank_Final_08.png"
import pos9 from "./../images/standings/final/ASfFL_TeamRank_Final_09.png"
import pos10 from "./../images/standings/final/ASfFL_TeamRank_Final_10.png"
import pos11 from "./../images/standings/final/ASfFL_TeamRank_Final_11.png"
import pos12 from "./../images/standings/final/ASfFL_TeamRank_Final_12.png"
import pos13 from "./../images/standings/final/ASfFL_TeamRank_Final_13.png"
import pos14 from "./../images/standings/final/ASfFL_TeamRank_Final_14.png"

const list = [
  { image: pos1 },
  { image: pos2 },
  { image: pos3 },
  { image: pos4 },
  { image: pos5 },
  { image: pos6 },
  { image: pos7 },
  { image: pos8 },
  { image: pos9 },
  { image: pos10 },
  { image: pos11 },
  { image: pos12 },
  { image: pos13 },
  { image: pos14 },
]

const top = [{ image: top1 }, { image: top2 }, { image: top3 }]

export default { list, top }
